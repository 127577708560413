import global from '@/locales/de/global'
import pageDefault from '@/locales/de/pages/default'
import requests from '@/locales/de/pages/requests'
import createRequest from '@/locales/de/pages/createRequest'
import documents from '@/locales/de/pages/documents'
import documentFolders from '@/locales/de/pages/documentFolders'
import settings from '@/locales/de/pages/settings'
import users from '@/locales/de/pages/users'
import templates from '@/locales/de/pages/templates'
import createTemplate from '@/locales/de/pages/createTemplate'
import duplicateTemplate from '@/locales/de/pages/duplicateTemplate'
import help from '@/locales/de/pages/help'

const de = {
  global,
  pages: {
    default: pageDefault,
    requests,
    createRequest,
    documents,
    documentFolders,
    settings,
    users,
    templates,
    createTemplate,
    duplicateTemplate,
    help
  }
}

export default de
