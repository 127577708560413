const createRequest = {
  meta: {
    title: 'Anfrage erstellen',
    heading: 'Anfrage erstellen',
    subHeading: 'Auf dieser Seite können Sie Anfragen manuell erstellen.'
  },
  components: {
    form: {
      buttons: {
        continue: 'Weiter',
        submit: 'Erstellen',
        editTemplate: 'Vorlage anpassen',
        previewTemplate: 'Vorschau',
        skipTemplateForm: 'Vorlage verwenden'
      },
      fields: {
        givenName: {
          label: 'Vorname *',
          placeholder: 'Vorname'
        },
        familyName: {
          label: 'Nachname *',
          placeholder: 'Nachname'
        },
        email: {
          label: 'E-Mail',
          placeholder: 'E-Mail'
        },
        customerId: {
          label: 'Referenznummer',
          placeholder: 'Referenznummer'
        },
        customerType: {
          label: 'Kundentyp *'
        },
        precedingContractUuid: {
          label: 'UUID der Voranfrage',
          placeholder: 'UUID der Voranfrage'
        },
        mailTemplateUuid: {
          label: 'Mail-Vorlage',
          placeholder: 'Mail-Vorlage'
        },
        documentFolderUuid: {
          label: 'Dokumentenmappe *',
          placeholder: 'Dokumentenmappe'
        },
        validity: {
          label: 'Ablauffrist *',
          placeholder: 'Ablauffrist'
        },
        comment: {
          label: 'Kommentar',
          placeholder: 'Kommentar'
        },
        contractSummaryTemplate: {
          label: 'VZF-Vorlage *',
          placeholder: 'VZF-Vorlage',
          options: {
            noTemplate: 'Keine Vorlage verwenden'
          }
        }
      },
      sections: {
        customerData: 'Kundendaten',
        customerData_help: 'Geben Sie hier den Vor- und Nachnamen des Kunden an. Eine persönliche Ansprache des Kunden in der generierten E-Mail wird somit möglich. Darüber hinaus können Sie die Anfrage über den Kundennamen zuordnen. <p>Geben Sie eine gültige E-Mail-Adresse des Kunden an, sofern die Vorvertrags-Dokumente per E-Mail an den Kunden versendet werden sollen. Ohne die Angabe einer E-Mail-Adresse ist der Versand der Dokumente nicht möglich. Sie haben jedoch die Möglichkeit, die Dokumente später manuell herunterzuladen.</p> <p>Sofern vorhanden, können Sie zudem eine Referenznummer aus Ihren Systemen angeben, um die Anfrage besser zuordnen zu können.</p> <p>Geben Sie bitte an, ob es sich um einen Privat- oder Geschäftskunden handelt.</p>',
        validity: 'Ablauffrist',
        validity_help: 'Geben Sie hier eine abweichende Ablauffrist für die Anfrage an, um die Standardfrist in den Einstellungen einmalig zu überschreiben. <ul> <li>Die Frist gibt an, wie lange sich der Kunde auf die Anfrage berufen und den Vertrag auf Basis der Anfrage verbindlich abschließen kann. </li> <li>Nach Ablauf der Frist wechselt die Anfrage in den Status "Abgelaufen".</li> <li> Eine Überschreibung der Standardfrist bietet sich beispielsweise dann an, wenn eine Anfrage aufgrund einer zeitlich begrenzten Aktion nur kurzzeitig gültig ist. </li> <li>Beachten Sie, dass die Verkürung der Ablauffrist nur aus objektiv nachvollziehbaren Gründen erlaubt ist.</li></ul>',
        contractData: 'Bestehender Vertrag',
        contractData_help: 'Handelt es sich um einen Vertragswechsel oder eine Vertragsverlängerung müssen in bestimmten Fällen die Vorvertraglichen Informationen für den ursprünglichen Vertrag ebenfalls erneut an den Kunden geschickt werden. \nGeben Sie die UUID der ursprünglichen Anfrage an, um neben den neu erzeugten Vorvertraglichen Informationen auch die ursprünglichen Dokumente an den Kunden zu versenden.',
        mailTemplate: 'Mail-Vorlage',
        mailTemplate_help: 'Wählen Sie hier das passende Mail-Template aus, welches Sie für Ihre Anfrage verwenden möchten. Ohne die Auswahl eines Mail-Templates ist der Versand der E-Mail nicht möglich. Die Mail-Templates können Sie unter "Einstellungen" erstellen und verwalten.',
        documentFolder: 'Dokumentenmappe',
        documentFolder_help: 'Wählen Sie hier die passende Dokumentenmappe zu dem betroffenen Produkt aus.<ul> <li> Mit dieser Auswahl definieren Sie, welche Dokumente (Vorvertraglichen Informationen) neben der Vertragszusammenfassung an den Kunden versendet werden sollen. </li> <li>Dokumentenmappen können Sie unter dem entsprechenden Menü-Punkt erstellen und verwalten.</li></ul>',
        contractSummaryTemplate: 'Vertragszusammenfassung (VZF)',
        contractSummaryTemplate_help: 'Wählen Sie eine VZF-Vorlage aus, um das Formular vorzubefüllen. Wählen Sie "Keine Vorlage verwenden", um eine gänzlich neue Vertragszusammenfassung zu erstellen. VZF-Vorlagen können unter dem entsprechenden Menü-Punkt erstellt und verwaltet werden. <p> **Bitte beachten Sie beim Ausfüllen des Formulars, dass Sie für den redaktionellen Inhalt selbst verantwortlich sind. Fehleingaben können dazu führen, dass ein nicht rechtskonformes Dokument erstellt wird. Nutzen Sie die vorhandenen Hilfetexte, wenn Sie sich bei einer Angabe unsicher sind.** </p>',
        requestData: 'Allgemein',
        otherData: 'Sonstiges'
      },
      messages: {
        errors: {
          wrongPrecedingRequestUuid: 'Es konnte kein Vorvertrag für die UUID {uuid} gefunden werden'
        }
      }
    },
    dialogs: {
      create: {
        title: 'Anfrage erstellen',
        text: '**Hinweis:** Bitte überprüfen Sie Ihre Eingaben. <ul> <li> **Beachten Sie, dass Sie für redaktionelle Inhalte selbst verantwortlich sind. Fehleingaben können dazu führen, dass ein nicht rechtskonformes Dokument erzeugt wird.** </li> <li>Mit der Bestätigung "Anfrage erstellen" wird die E-Mail mit der VZF und allen weiteren Vorvertraglichen Informationen an den Kunden versendet, sofern Sie eine E-Mail-Adresse angegeben haben. Die angegebene Frist ist dann für Sie bindend. </li> <li> Wenn Sie keine E-Mail-Adresse angegeben haben, haben Sie die Möglichkeit, die Dokumente in der Anfragenübersicht herunterzuladen.</li></ul>',
        textEmail: '**Anfrage wird versendet an: `{email}`**',
        textNoEmail: '**Achtung: Sie haben keine E-Mail-Adresse angegeben.**',
        buttons: {
          cancel: 'Abbrechen',
          confirm: 'Anfrage erstellen'
        }
      },
      leaveRouteConfirmation: {
        title: 'Erstellung abbrechen',
        text: 'Möchten Sie die Erstellung der Anfrage abbrechen? Ihre Daten werden nicht gespeichert.',
        buttons: {
          cancel: 'Weiter bearbeiten',
          confirm: 'Erstellung abbrechen'
        }
      }
    },
    messages: {
      error: 'Das Erstellen der Anfrage ist fehlgeschlagen',
      success: 'Anfrage erfolgreich erstellt'
    },
    buttons: {
      backButton: {
        text: 'Zurück zur Übersicht'
      }
    }
  }
}

export default createRequest
