import {
  createContractSummaryHandle,
  previewContractSummaryHandle,
  listContractSummariesHandle,
  getContractSummaryHandle,
  deleteContractSummaryHandle,
  getDefaultValuesHandle
} from '@/api/contractSummaries.api'

import i18n from '@/i18n'

import { getStateFromTemplate } from '@/helpers/templates.helper'

import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import get from 'lodash/get'
import set from 'lodash/set'
import camelCase from 'lodash/camelCase'
import mergeWith from 'lodash/mergeWith'

import { v4 as uuid } from 'uuid'

import { cleanObject } from '@/helpers/utility.helper'

const state = {
  done: false,
  checkboxListenerEnabled: true,
  defaultValues: {
    serviceSegment: {
      features: '',
      devices: '',
      volumes: {
        service: '',
        metering: '',
        volume: ''
      }
    },
    periodSegment: {
      contractTermRemedyTexts: '',
      terminationConditionTexts: '',
      contractExtensionTexts: '',
      earlyTerminationTexts: '',
      deviceUnlockTexts: ''
    },
    priceSegment: {
      priceTable: {
        invoiceLine: '',
        price: ''
      },
      priceTableEntry: {
        oneTimePrice: {
          price: ''
        },
        recurringPrice: {
          pricePerCycle: '',
          pricePerMonth: '',
          validity: ''
        }
      },
      priceVolumeTable: {
        service: '',
        metering: '',
        price: ''
      },
      otherPrices: '',
      priceConditions: ''
    },
    disabilitySegment: {
      texts: ''
    },
    otherSegment: {
      texts: ''
    }
  },
  formData: {
    baseSegment: {
      introHeading: '',
      introText: '',
      footnoteText: '',
      productName: ''
    },
    serviceSegment: {
      heading: '',
      featuresHeading: '',
      features: [''],
      devicesHeading: '',
      devices: [],
      volumes: [],
      volumeServiceHeading: '',
      volumeMeteringHeading: '',
      volumeVolumeHeading: '',
      euFairUseSegment: {
        isPossible: false,
        isRestricted: false,
        referenceText: ''
      },
      tvChannelsHeading: '',
      tvChannelsText: ''
    },
    bandwidthSegment: {
      heading: '',
      mobileHeading: '',
      mobileColumnHeading: '',
      mobileBandwidth: [
        {
          upload: '',
          download: ''
        }
      ],
      landlineHeading: '',
      landlineColumnHeading: '',
      landlineColumnMinHeading: '',
      landlineColumnAvgHeading: '',
      landlineColumnMaxHeading: '',
      landlineBandwidth: [
        {
          download: '',
          upload: ''
        },
        {
          download: '',
          upload: ''
        },
        {
          download: '',
          upload: ''
        }
      ],
      remedyText: ''
    },
    priceSegment: {
      heading: '',
      priceCycle: 30,
      invoiceLineHeading: '',
      priceHeading: '',
      priceOneTimeHeading: '',
      pricePerCycleHeading: '',
      pricePerMonthHeading: '',
      validityHeading: '',
      priceTableNote: '',
      priceTable: [
        {
          invoiceLine: '',
          price: ''
        }
      ],
      priceVolumeTable: [],
      otherPricesHeading: '',
      otherPrices: [],
      priceConditionsHeading: '',
      priceConditions: [],
      priceVolumeTableServiceHeading: '',
      priceVolumeTableMeteringHeading: '',
      priceVolumeTablePriceHeading: ''
    },
    periodSegment: {
      heading: '',
      contractTermText: '',
      contractTerm: '',
      contractTermRemedyTexts: [],
      terminationConditionText: '',
      terminationConditionTexts: [''],
      contractExtensionText: '',
      contractExtensionTexts: [''],
      earlyTerminationText: '',
      earlyTerminationTexts: [''],
      deviceUnlockText: '',
      deviceUnlockTexts: []
    },
    disabilitySegment: {
      heading: '',
      placeholder: '',
      texts: []
    },
    otherSegment: {
      heading: '',
      texts: []
    }
  },
  controlData: {
    serviceSegment: {
      featuresMap: [
        uuid()
      ],
      hasDevices: false,
      hasVolumes: false,
      hasTvChannelsText: false,
      devicesMap: [],
      volumesMap: []
    },
    periodSegment: {
      terminationConditionTextsMap: [
        uuid()
      ],
      contractExtensionTextsMap: [
        uuid()
      ],
      earlyTerminationTextsMap: [
        uuid()
      ],
      hasContractTermRemedyTexts: false,
      hasDeviceUnlockTexts: false,
      contractTermRemedyTextsMap: [],
      deviceUnlockTextsMap: []
    },
    priceSegment: {
      priceTable: [''],
      priceTableMap: [
        uuid()
      ],
      hasPriceVolumeTable: false,
      priceVolumeTableMap: [],
      hasOtherPrices: false,
      otherPricesMap: [],
      hasPriceConditions: false,
      priceConditionsMap: []
    },
    bandwidthSegment: {
      hasBandwidthSegment: false,
      hasLandlineBandwidth: true,
      hasMobileBandwidth: true
    },
    disabilitySegment: {
      hasTexts: false,
      textsMap: []
    },
    otherSegment: {
      hasTexts: false,
      textsMap: []
    }
  },
  errorData: {},
  labelParams: {
    priceSegment: {
      priceTable: {
        pricePerCycle: {
          count: {
            origin: 'formData',
            path: 'priceSegment.priceCycle'
          }
        }
      }
    }
  },
  loading: false,
  currentSegment: null,
  contractSummaryTemplates: [],
  contractSummaryTemplate: null,
  initialData: {
    formData: null,
    controlData: null
  },
  resetCounter: 0,
  formDataDefaultValues: {}
}

const getters = {
  formData (state) {
    return state.formData
  },
  controlData (state) {
    return state.controlData
  },
  loading (state) {
    return state.loading
  },
  currentSegment (state) {
    return state.currentSegment
  },
  contractSummaryTemplates (state) {
    return state.contractSummaryTemplates
  },
  contractSummaryTemplate (state) {
    return state.contractSummaryTemplate
  },
  defaultPriceTableEntries (state) {
    return state.defaultValues.priceSegment.priceTableEntry
  },
  resetCounter (state) {
    return state.resetCounter
  },
  done (state) {
    return state.done
  },
  errorData (state) {
    return state.errorData
  },
  labelParams (state) {
    return state.labelParams
  },
  defaultValues (state) {
    return state.formDataDefaultValues
  }
}

const actions = {
  setCheckboxListenerEnabled ({ commit }, enabled) {
    commit('SET_CHECKBOX_LISTENER_ENABLED', enabled)
  },
  async createContractSummary ({ commit, dispatch, state }, { name, template, callee }) {
    let response
    try {
      commit('LOADING', true)
      response = await createContractSummaryHandle({ name, template })
    } catch (e) {
      response = e.response
      let errorMessage = response.statusText
      errorMessage = i18n.t(`global.api.errorMessages.contractSummaries.${errorMessage}`)
      dispatch('messageQueue/queueError', errorMessage, { root: true })
      dispatch('setErrorData', response.data)
    } finally {
      commit('LOADING', false)
    }
    return response
  },
  async previewContractSummary ({ commit }, formData) {
    try {
      commit('LOADING', true)
      return await previewContractSummaryHandle(formData)
    } catch (e) {
      console.error(e.message)
    } finally {
      commit('LOADING', false)
    }
  },
  async listContractSummaries ({ commit }) {
    try {
      commit('LOADING', true)
      const response = await listContractSummariesHandle()
      commit('SET_CONTRACT_SUMMARY_TEMPLATES', response.data)
    } catch (e) {
      console.error(e.message)
    } finally {
      commit('LOADING', false)
    }
  },
  async getContractSummary ({ commit, dispatch }, templateUuid) {
    try {
      commit('LOADING', true)
      const response = await getContractSummaryHandle(templateUuid)
      const template = response.data
      dispatch('setContractSummaryTemplate', template)
    } catch (e) {
      console.error(e.message)
    } finally {
      commit('LOADING', false)
    }
  },
  async deleteContractSummary ({ commit, dispatch }, templateUuid) {
    try {
      commit('LOADING', true)
      await deleteContractSummaryHandle(templateUuid)
      dispatch('listContractSummaries')
    } catch (e) {
      console.error(e.message)
    } finally {
      commit('LOADING', false)
    }
  },
  async fetchDefaultValues ({ commit }) {
    try {
      commit('LOADING', true)
      const response = await getDefaultValuesHandle()
      const defaultValues = mergeWith(
        cloneDeep(i18n.t('global.components.contractSummaryForm.defaultValues')),
        cleanObject(response.data),
        (objValue, srcValue) => {
          return { ...objValue, ...srcValue }
        }
      )
      commit('SET_INITIAL_DATA', defaultValues)
      commit('SET_DEFAULT_VALUES', defaultValues)
      commit('SET_FORM_DATA_DEFAULT_VALUES', defaultValues)
    } catch (e) {
      console.error(e.message)
      console.log(e.response)
    } finally {
      commit('LOADING', false)
    }
  },
  setDefaultValues ({ commit }, defaultValues) {
    commit('SET_DEFAULT_VALUES', defaultValues)
  },
  setDefaultValue ({ commit }, { path, value }) {
    commit('SET_DEFAULT_VALUE', { path, value })
  },
  addDefaultValue ({ commit, state }, { field, index }) {
    commit('ADD_DEFAULT_VALUE', { field, index })
    const error = get(state.errorData, field, {})
    if (Object.keys(error).length > 0) {
      const errorType = error.type
      if (errorType === 'value_error.list.min_items') {
        const value = get(state.formData, field, [])
        if (value.length >= error.ctx.limit_value) {
          commit('RESET_FIELD_ERROR_DATA', { field })
        }
      }
    }
  },
  addInitialDefaultValue ({ commit }, { field }) {
    commit('ADD_INITIAL_DEFAULT_VALUE', { field })
  },
  removeDefaultValue ({ commit, state }, { field, index }) {
    commit('REMOVE_DEFAULT_VALUE', { field, index })
    const error = get(state.errorData, field, {})
    if (Object.keys(error).length > 0) {
      const errorType = error.type
      if (errorType === 'value_error.list.max_items') {
        const value = get(state.formData, field, [])
        if (value.length <= error.ctx.limit_value) {
          commit('RESET_FIELD_ERROR_DATA', { field })
        }
      }
    }
  },
  addDefaultPrice ({ commit }, { field, index }) {
    commit('ADD_DEFAULT_PRICE', { field, index })
    commit('ADD_PRICE_TYPE', { field, index })
  },
  removeDefaultPrice ({ commit }, { field, index }) {
    commit('REMOVE_DEFAULT_PRICE', { field, index })
    commit('REMOVE_PRICE_TYPE', { field, index })
  },
  setPriceType ({ commit }, { priceType, index }) {
    commit('SET_PRICE_TYPE', { priceType, index })
  },
  setFormValue ({ commit }, { field, value, skipReset = false }) {
    if (!skipReset) {
      commit('RESET_FIELD_ERROR_DATA', { field })
    }
    commit('SET_FORM_VALUE', { field, value })
  },
  setFormComplete ({ commit }, data) {
    commit('SET_FORM_COMPLETE', data)
  },
  setControlDataComplete ({ commit }, data) {
    commit('SET_CONTROL_DATA_COMPLETE', data)
  },
  setControlDataValue ({ commit }, { field, value }) {
    commit('SET_CONTROL_DATA_VALUE', { field, value })
  },
  setCurrentSegment ({ commit }, currentSegment) {
    commit('SET_CURRENT_SEGMENT', currentSegment)
  },
  setContractSummaryTemplates ({ commit }, contractSummaryTemplates) {
    commit('SET_CONTRACT_SUMMARY_TEMPLATES', contractSummaryTemplates)
  },
  setContractSummaryTemplate ({ commit, dispatch, state }, contractSummaryTemplate) {
    dispatch('setCheckboxListenerEnabled', false)
    commit('SET_CONTRACT_SUMMARY_TEMPLATE', contractSummaryTemplate)
    if (contractSummaryTemplate !== null) {
      const res = getStateFromTemplate(contractSummaryTemplate, state.formData, state.controlData, state.defaultValues.priceSegment.priceTableEntry)
      dispatch('setControlDataComplete', res.controlData)
      dispatch('setFormComplete', res.formData)
    } else {
      dispatch('reset')
    }
    dispatch('setCheckboxListenerEnabled', true)
  },
  setInitialData ({ commit }, defaultValues) {
    commit('SET_INITIAL_DATA', defaultValues)
  },
  reset ({ commit }) {
    commit('RESET')
    commit('INCREMENT_RESET_COUNTER')
  },
  setDone ({ commit }, done) {
    commit('SET_DONE', done)
  },
  setErrorData ({ commit }, errorData) {
    commit('SET_ERROR_DATA', errorData)
  },
  resetErrorData ({ commit }) {
    commit('RESET_ERROR_DATA')
  }
}

const mutations = {
  SET_CHECKBOX_LISTENER_ENABLED (state, enabled) {
    state.checkboxListenerEnabled = enabled
  },
  SET_DEFAULT_VALUES (state, defaultValues) {
    state.formData = merge(cloneDeep(state.formData), cloneDeep(defaultValues))
  },
  SET_DEFAULT_VALUE (state, { path, value }) {
    set(state.formData, path, value)
  },
  ADD_DEFAULT_VALUE (state, { field, index }) {
    const defaultValue = cloneDeep(get(state.defaultValues, field))
    const currentValue = cloneDeep(get(state.formData, field))
    currentValue.splice(index + 1, 0, defaultValue)
    set(state.formData, field, currentValue)
    const mappingField = `${field}Map`
    const currentMapping = cloneDeep(get(state.controlData, mappingField))
    const fieldUuid = uuid()
    currentMapping.splice(index + 1, 0, fieldUuid)
    set(state.controlData, mappingField, currentMapping)
  },
  ADD_INITIAL_DEFAULT_VALUE (state, { field }) {
    if (state.checkboxListenerEnabled) {
      const formField = get(state.formData, field)
      if (formField === null || (Array.isArray(formField) && formField.length === 0)) {
        const defaultValue = cloneDeep(get(state.defaultValues, field))
        set(state.formData, field, [defaultValue])
        const mappingField = `${field}Map`
        const fieldUuid = uuid()
        set(state.controlData, mappingField, [fieldUuid])
      }
    }
  },
  REMOVE_DEFAULT_VALUE (state, { field, index }) {
    const mappingField = `${field}Map`
    const currentMapping = cloneDeep(get(state.controlData, mappingField))
    currentMapping.splice(index, 1)
    set(state.controlData, mappingField, currentMapping)
    const currentValue = cloneDeep(get(state.formData, field))
    currentValue.splice(index, 1)
    set(state.formData, field, currentValue)
  },
  ADD_DEFAULT_PRICE (state, { field, index }) {
    const defaultValue = cloneDeep(get(state.defaultValues, field))
    const currentValue = cloneDeep(get(state.formData, field))
    currentValue.splice(index + 1, 0, defaultValue)
    set(state.formData, field, currentValue)
    const mappingField = `${field}Map`
    const currentMapping = cloneDeep(get(state.controlData, mappingField))
    const fieldUuid = uuid()
    currentMapping.splice(index + 1, 0, fieldUuid)
    set(state.controlData, mappingField, currentMapping)
  },
  REMOVE_DEFAULT_PRICE (state, { field, index }) {
    const mappingField = `${field}Map`
    const currentMapping = cloneDeep(get(state.controlData, mappingField))
    currentMapping.splice(index, 1)
    set(state.controlData, mappingField, currentMapping)
    const currentValue = cloneDeep(get(state.formData, field))
    currentValue.splice(index, 1)
    set(state.formData, field, currentValue)
  },
  ADD_PRICE_TYPE (state, { field, index }) {
    const defaultValue = ''
    const currentValue = cloneDeep(get(state.controlData, field))
    currentValue.splice(index + 1, 0, defaultValue)
    set(state.controlData, field, currentValue)
  },
  REMOVE_PRICE_TYPE (state, { field, index }) {
    const currentValue = cloneDeep(get(state.controlData, field))
    currentValue.splice(index, 1)
    set(state.controlData, field, currentValue)
  },
  SET_PRICE_TYPE (state, { priceType, index }) {
    if (state.currentSegment === 'priceSegment') {
      const currentValue = cloneDeep(get(state.formData, 'priceSegment.priceTable'))
      const priceTableEntry = get(state.defaultValues.priceSegment.priceTableEntry, priceType)
      set(currentValue[index], 'price', priceTableEntry)
      set(state.formData.priceSegment, 'priceTable', currentValue)
    }
  },
  SET_FORM_VALUE (state, { field, value }) {
    const formData = cloneDeep(state.formData)
    set(formData, field, value)
    set(state, 'formData', formData)
  },
  SET_CONTROL_DATA_VALUE (state, { field, value }) {
    const formData = cloneDeep(state.controlData)
    set(formData, field, value)
    set(state, 'controlData', formData)
  },
  SET_FORM_COMPLETE (state, data) {
    set(state, 'formData', data)
  },
  SET_CONTROL_DATA_COMPLETE (state, data) {
    set(state, 'controlData', cloneDeep(data))
  },
  SET_CURRENT_SEGMENT (state, currentSegment) {
    state.currentSegment = currentSegment
  },
  SET_CONTRACT_SUMMARY_TEMPLATES (state, contractSummaryTemplates) {
    state.contractSummaryTemplates = contractSummaryTemplates
  },
  SET_CONTRACT_SUMMARY_TEMPLATE (state, contractSummaryTemplate) {
    state.contractSummaryTemplate = contractSummaryTemplate
  },
  LOADING (state, loading) {
    state.loading = loading
  },
  SET_INITIAL_DATA (state, defaultValues) {
    if (state.initialData.formData === null) {
      state.initialData.formData = merge(cloneDeep(state.formData), cloneDeep(defaultValues))
    }
    if (state.initialData.controlData === null) {
      state.initialData.controlData = cloneDeep(state.controlData)
    }
  },
  RESET (state) {
    state.formData = cloneDeep(state.initialData.formData)
    state.controlData = cloneDeep(state.initialData.controlData)
  },
  INCREMENT_RESET_COUNTER (state) {
    state.resetCounter++
  },
  SET_DONE (state, done) {
    state.done = done
  },
  SET_ERROR_DATA (state, errorData) {
    const data = {}
    const messages = i18n.t('global.api.errorMessages.contractSummaries')
    errorData.detail
      .forEach((err) => {
        let sliceIndex = 1
        if (err.loc.indexOf('template') >= 0) {
          // error creating template
          sliceIndex = err.loc.indexOf('template') + 1
        }
        if (err.loc.indexOf('contract_summary_template') >= 0) {
          // error creating eecc request
          sliceIndex = err.loc.indexOf('contract_summary_template') + 1
        }
        const errorPath = err.loc
          .slice(sliceIndex)
          .map((entry) => {
            return camelCase(entry)
          })
        let errorMessage = err.msg
        if (messages[err.type]) {
          if (err.ctx) {
            const number = err.ctx.limit_value
            const translations = messages[err.type]
            errorMessage = messages[err.type].default.replace('{number}', number)
            if (translations[number]) {
              errorMessage = messages[err.type][number].replace('{number}', number)
            }
          } else {
            errorMessage = messages[err.type]
          }
        }
        set(data, errorPath, {
          message: errorMessage,
          type: err.type,
          ctx: err.ctx
        })
      })
    state.errorData = data
  },
  RESET_FIELD_ERROR_DATA (state, { field }) {
    const data = cloneDeep(state.errorData)
    set(data, field, {})
    state.errorData = data
  },
  RESET_ERROR_DATA (state) {
    const errorData = cloneDeep(state.errorData)
    Object.keys(state.formData)
      .forEach((formKey) => {
        delete errorData[formKey]
      })
    state.errorData = errorData
  },
  SET_FORM_DATA_DEFAULT_VALUES (state, defaultValues) {
    state.formDataDefaultValues = defaultValues
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
