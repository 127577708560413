import config from '@/config/config'
import VueI18n from '@/i18n'

const colors = config.getRaw('requests.states.colors')

class RequestStates {
  ACCEPTED = 'accepted'
  CANCELED = 'canceled'
  DECLINED = 'declined'
  EXPIRED = 'expired'
  OPEN = 'open'

  transitions = {
    accepted: [
      { to: this.CANCELED, permission: null }
    ],
    canceled: [],
    declined: [],
    expired: [],
    open: [
      { to: this.ACCEPTED, permission: null },
      { to: this.DECLINED, permission: null }
    ]
  }

  colors = colors

  isValidState (state) {
    return [this.ACCEPTED, this.CANCELED, this.DECLINED, this.EXPIRED, this.OPEN].includes(state)
  }

  getTransitions (state) {
    if (!this.isValidState(state)) {
      return null
    }

    return this.transitions[state]
  }

  hasTransitions (state) {
    const transitions = this.getTransitions(state)
    return transitions ? transitions.length > 0 : false
  }

  canTransition (currentState, newState) {
    if (!this.hasTransitions(currentState)) {
      return false
    }

    return this.transitions[currentState].some(({ to }) => to === newState)
  }

  getColor (state) {
    if (!this.isValidState(state)) {
      return null
    }

    return this.colors[state]
  }

  getTranslation (state) {
    return VueI18n.t(`global.requests.states.${state}`)
  }

  getTransitionTranslation (state) {
    return VueI18n.t(`global.requests.states.transitions.${state}`)
  }
}

export default new RequestStates()
