const templates = {
  components: {
    management: {
      heading: 'VZF-Vorlagen verwalten',
      table: {
        headers: {
          name: 'Name',
          createdOn: 'Erstellt',
          uuid: 'UUID'
        },
        search: {
          label: 'VZF suchen',
          hint: 'Suche nach Vertragszusammenfassungen'
        },
        actions: {
          preview: 'Vorschau anzeigen',
          copyJson: 'JSON kopieren',
          duplicate: 'Vorlage duplizieren',
          delete: 'Vorlage löschen'
        }
      },
      snackbars: {
        copyJsonSuccessText: 'JSON wurde erfolgreich in die Zwischenablage kopiert',
        saveTemplateSuccessText: 'Vorlage wurde erfolgreich gespeichert',
        deleteTemplateSuccessText: 'Vorlage wurde erfolgreich gelöscht'
      },
      dialogs: {
        preview: {
          title: '{template} (Vorschau)'
        },
        delete: {
          title: 'VZF-Vorlage löschen',
          text: 'Möchten Sie die Vorlage "{template}" endgültig löschen? Diese Aktion kann nicht rückgängig gemacht werden.',
          cancelButtonText: 'Abbrechen',
          deleteButtonText: 'Löschen'
        }
      },
      buttons: {
        createTemplateButtonText: 'Vorlage erstellen'
      }
    }
  },
  meta: {
    title: 'VZF-Vorlagen',
    heading: 'VZF-Vorlagen',
    subHeading: 'Hier können Sie VZF-Vorlagen für bestimmte Produkte oder Produktgruppen erstellen und verwalten, um die Erstellung von Anfragen zu erleichtern.'
  }
}

export default templates
