import {
  createRequest,
  getRequest,
  getRequests,
  searchRequests,
  acceptRequest,
  cancelRequest,
  declineRequest,
  resendRequest,
  updateRequest
} from '@/api/requests.api'

import { handleError, handleSuccess } from '@/helpers/api.helper'
import requestStates from '@/types/RequestStates'

const state = {
  requests: [],
  activeRequest: null,
  total: 0,
  page: 1,
  itemsPerPage: 10,
  searchTerm: '',
  loading: false,
  loadingState: false,
  loadingRequest: false,
  loadingResend: false,
  loadingCreate: false,
  updatingRequest: false
}

const getters = {
  requests (state) {
    return state.requests
  },
  total (state) {
    return state.total
  },
  loading (state) {
    return state.loading
  },
  page (state) {
    return state.page
  },
  itemsPerPage (state) {
    return state.itemsPerPage
  },
  searchTerm (state) {
    return state.searchTerm
  },
  loadingState (state) {
    return state.loadingState
  },
  loadingRequest (state) {
    return state.loadingRequest
  },
  loadingResend (state) {
    return state.loadingResend
  },
  activeRequest (state) {
    return state.activeRequest
  },
  loadingCreate (state) {
    return state.loadingCreate
  },
  updatingRequest (state) {
    return state.updatingRequest
  }
}

const actions = {
  updateOptions ({ commit }, { page, itemsPerPage }) {
    commit('PAGE', page)
    commit('ITEMS_PER_PAGE', itemsPerPage)
  },
  async getRequests ({ commit, getters }) {
    try {
      commit('LOADING', true)

      const limit = getters.itemsPerPage
      const offset = limit * (getters.page - 1)

      let result

      if (getters.searchTerm.length > 0) {
        result = await searchRequests(limit, offset, getters.searchTerm)
      } else {
        result = await getRequests(limit, offset)
      }

      const { data } = result

      commit('REQUESTS', data.result)
      commit('TOTAL', data.total)
    } catch (e) {
      await handleError(e.response, {}, e.message)
    } finally {
      commit('LOADING', false)
    }
  },
  async updateRequestState ({ commit, dispatch }, { uuid, newState }) {
    try {
      commit('LOADING_STATE', true)

      if (newState === requestStates.ACCEPTED) {
        await acceptRequest(uuid)
      } else if (newState === requestStates.CANCELED) {
        await cancelRequest(uuid)
      } else if (newState === requestStates.DECLINED) {
        await declineRequest(uuid)
      }

      await dispatch('getRequests')
    } catch (e) {
      await handleError(e.response, {}, e.message)
    } finally {
      commit('LOADING_STATE', false)
    }
  },
  async getRequest ({ commit }, uuid) {
    let request = null

    try {
      commit('LOADING_REQUEST', true)

      const { data } = await getRequest(uuid)
      request = data
    } catch (e) {
      await handleError(e.response, {}, e.message)
    } finally {
      commit('LOADING_REQUEST', false)
    }

    return request
  },

  async updateRequest ({ commit }, { uuid, email, customerId, handlers = {} }) {
    try {
      commit('UPDATING_REQUEST', true)

      const requestData = {
        surrogatCustomerUid: customerId,
        email: email || null
      }

      const response = await updateRequest(uuid, requestData)
      await handleSuccess(response, handlers)
    } catch (e) {
      await handleError(e.response, handlers, e.message)
    } finally {
      commit('UPDATING_REQUEST', false)
    }
  },
  async resendRequest ({ commit }, uuid) {
    try {
      commit('LOADING_RESEND', true)

      await resendRequest(uuid)
    } catch (e) {
      await handleError(e.response, {}, e.message)
    } finally {
      commit('LOADING_RESEND', false)
    }
  },
  async createRequest (
    { commit, dispatch },
    {
      contractSummaryTemplate,
      customerData,
      documentFolderUuid,
      expiryDate,
      precedingContractUuid,
      surrogatCustomerUid,
      mailTemplateUuid,
      comment,
      handlers
    }
  ) {
    try {
      commit('LOADING_CREATE', true)

      const response = await createRequest(
        contractSummaryTemplate,
        customerData,
        documentFolderUuid,
        expiryDate,
        precedingContractUuid,
        surrogatCustomerUid,
        mailTemplateUuid,
        comment
      )
      await handleSuccess(response, handlers)
    } catch (e) {
      await handleError(e.response, handlers, e.message)

      if (e.response.status === 422) {
        await dispatch('contractSummaries/setErrorData', e.response.data, { root: true })
      }
    } finally {
      commit('LOADING_CREATE', false)
    }
  }
}

const mutations = {
  REQUESTS (state, requests) {
    state.requests = requests
  },
  TOTAL (state, total) {
    state.total = total
  },
  LOADING (state, loading) {
    state.loading = !!loading
  },
  LOADING_STATE (state, loading) {
    state.loadingState = !!loading
  },
  LOADING_REQUEST (state, loading) {
    state.loadingRequest = !!loading
  },
  LOADING_RESEND (state, loading) {
    state.loadingResend = !!loading
  },
  LOADING_CREATE (state, loading) {
    state.loadingCreate = !!loading
  },
  PAGE (state, page) {
    state.page = page
  },
  ITEMS_PER_PAGE (state, itemsPerPage) {
    state.itemsPerPage = itemsPerPage
  },
  SEARCH_TERM (state, searchTerm) {
    state.searchTerm = searchTerm
  },
  ACTIVE_REQUEST (state, requestUuid) {
    state.activeRequest = state.requests.find(({ uuid }) => uuid === requestUuid) ?? null
  },
  UPDATING_REQUEST (state, updating) {
    state.updatingRequest = updating
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
