const settings = {
  meta: {
    title: 'Einstellungen',
    heading: 'Einstellungen',
    subHeading: 'Hier können Sie generelle Einstellungen für die Versendung Ihrer Anfragen und die Anfragen-Verwaltung konfigurieren.'
  },
  components: {
    companyDataForm: {
      heading: 'VZF-Template',
      heading_help: {
        default: 'Bitte beachten Sie, dass Sie mindestens Ihren Unternehmensnamen sowie eine Kontaktmöglichkeit (E-Mail Adresse oder Telefonnummer) angebeben müssen, an die sich der Kunde bei Problemen oder Beschwerden wenden kann. \nSie können Ihr Unternehmens-Logo hochladen, um das VZF-Dokument zu customizieren.',
        clerk: 'Die hier angegebenen Unternehmensdaten sowie das Logo erscheinen automatisch auf den von Ihnen generierten VZF-Dokumenten. <p>Bitte beachten Sie, dass Änderungen nur von Nutzern mit den Rollen "Administrator" oder "Manager" vorgenommen werden können. Falls Sie Änderungen vornehmen möchten, wenden Sie sich bitte an Ihren zuständigen Administrator.</p>'
      },
      subHeading: 'Bitte geben Sie Ihre Unternehmensdaten ein. Diese werden auf den autogenerierten Dokumenten platziert.',
      fields: {
        companyName: {
          label: 'Unternehmensname *',
          placeholder: 'Unternehmensname'
        },
        contactLine0: {
          label: 'Kontaktzeile 1',
          placeholder: 'Kontaktzeile 1'
        },
        contactLine1: {
          label: 'Kontaktzeile 2',
          placeholder: 'Kontaktzeile 2'
        },
        contactLine2: {
          label: 'Kontaktzeile 3',
          placeholder: 'Kontaktzeile 3'
        },
        contactLine3: {
          label: 'Kontaktzeile 4',
          placeholder: 'Kontaktzeile 4'
        },
        contactLine4: {
          label: 'Kontaktzeile 5',
          placeholder: 'Kontaktzeile 5'
        },
        contactLine5: {
          label: 'Kontaktzeile 6',
          placeholder: 'Kontaktzeile 6'
        },
        companyLogoUuid: {
          text: 'Legen Sie ein Logo hier ab oder <span class="text-decoration-underline blue--text">wählen Sie manuell eines aus</span>.',
          hint: 'Unterstützte Dateiformate: PNG, JPEG',
          preview: {
            heading: 'Logo',
            alt: 'Logo'
          }
        }
      },
      buttons: {
        submit: 'Unternehmensdaten speichern'
      }
    },
    stylesForm: {
      heading: 'Farben und Texte',
      heading_help: {
        default: 'Stellen Sie hier die Schriftgröße des Haupttextes sowie die Schriftfarbe der Überschriften ein. <ul> <li>Beachten Sie, dass die empfohlene Schriftgröße 12 pt beträgt. Die Überschriften haben automatisch eine größere Schriftgröße.</li> <li>Mit der "Textfarbe" definieren Sie die Farbe der Überschriften in Ihren VZF-Dokumenten. Mit der "Hintergrundfarbe" legen Sie den Hintergrund der Überschriften fest.</li> <li> In der Vorschau können Sie sich den Kontrast der beiden ausgewählten Farben anzeigen lassen. Bitte beachten Sie, dass nicht empfohlene Kontraste dazu führen können, dass die Voraussetzung der Barrierefreiheit und Lesbarkeit in Ihren VZF-Dokumenten nicht gewährleistet ist. </li> </ul>',
        clerk: 'Bitte beachten Sie, dass Änderungen nur von Nutzern mit den Rollen "Administrator" oder "Manager" vorgenommen werden können. Falls Sie Änderungen vornehmen möchten, wenden Sie sich bitte an Ihren zuständigen Administrator.</p>'
      },
      subHeading: 'Legen Sie die Textgröße für den Fließtext sowie zwei Hauptfarben für die Überschriften in Ihren VZF-Dokumenten fest.',
      fields: {
        fontSize: {
          heading: 'Textgröße',
          label: 'Textgröße *',
          placeholder: 'Textgröße'
        },
        primaryColor: {
          heading: 'Textfarbe'
        },
        secondaryColor: {
          heading: 'Hintergrundfarbe'
        }
      },
      preview: {
        heading: 'Vorschau',
        texts: {
          lg: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. (12&nbsp;pt)',
          md: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. (11&nbsp;pt)',
          sm: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. (10&nbsp;pt)'
        },
        contrast: 'Kontrast',
        contrast_help: 'Die Web Accessibility Initiative (WAI) des World Wide Web Consortium (W3C) hat in ihren Web Accessibility Guidelines (WCAG) 2 festgehalten, welchen Kontrast Texte und Logos haben sollten.\nEmpfohlen wird mindestens Level AA.\n\nMindestkontraste:\n- Level AA:\n\t- Normaler Text: 4.5\n\t- Großer Text: 3\n- Level AAA:\n\t- Normaler Text: 7\n\t- Großer Text: 4.5',
        poorContrast: 'Schlecht',
        goodContrast: 'Gut',
        excellentContrast: 'Sehr gut'
      },
      buttons: {
        submit: 'Einstellungen speichern'
      }
    },
    periodsForm: {
      heading: 'Fristen und Gültigkeiten',
      heading_help: {
        default: 'Bei den vorausgefüllten Standardwerten handelt es sich lediglich um Empfehlungen. Nähere Informationen zu den Fristen und Status entnehmen Sie bitte dem Zustandsdiagramm (s. User Manual). <ul> <li> **Löschfrist:** Beachten Sie, dass für die Anfragen-Erstellung personenbezogene Daten in diesem System gespeichert werden. Löschfristen für nicht mehr benötigte Informationen müssen gem. DSGVO eingehalten werden. Beachten Sie auch, dass das Kürzen der Löschfrist dazu führen kann, dass bestimmte Anfragen sofort gelöscht werden. </li> <li> **Ablauffrist:** Beachten Sie, dass dem Kunden gem. TKG eine angemessene Überlegungszeit eingeräumt werden muss. </li> <li> **Vorhaltefrist:** Empfohlen wird eine Vorhaltefrist, die mindestens die Vertragslaufzeit umfasst. Vorvertrags-Dokumente müssen ggf. bei Vertragswechsel oder Vertragsverlängerung erneut an den Kunden versendet werden. Der Mindestwert für die Vorhaltefrist beträgt aus diesem Grund 366 Tage.</li> </ul>',
        clerk: 'Bei den vorausgefüllten Standardwerten handelt es sich lediglich um Empfehlungen. Nähere Informationen zu den Fristen und Status entnehmen Sie bitte dem Zustandsdiagramm (s. User Manual). <ul> <li> **Löschfrist:** Beachten Sie, dass für die Anfragen-Erstellung personenbezogene Daten in diesem System gespeichert werden. Löschfristen für nicht mehr benötigte Informationen müssen gem. DSGVO eingehalten werden. Beachten Sie auch, dass das Kürzen der Löschfrist dazu führen kann, dass bestimmte Anfragen sofort gelöscht werden.</li> <li> **Ablauffrist:** Beachten Sie, dass dem Kunden gem. TKG eine angemessene Überlegungszeit eingeräumt werden muss. </li> <li> **Vorhaltefrist:** Empfohlen wird eine Vorhaltefrist, die mindestens die Vertragslaufzeit umfasst. Vorvertrags-Dokumente müssen ggf. bei Vertragswechsel oder Vertragsverlängerung erneut an den Kunden versendet werden. Der Mindestwert für die Vorhaltefrist beträgt aus diesem Grund 366 Tage.</li> </ul> \n Bitte beachten Sie, dass Änderungen nur von Nutzern mit den Rollen "Administrator" oder "Manager" vorgenommen werden können. Falls Sie Änderungen vornehmen möchten, wenden Sie sich bitte an Ihren zuständigen Administrator.'
      },
      fields: {
        deletionPeriod: {
          heading: 'Löschfrist',
          subHeading: 'Frist, nach welcher Anfragen in den Status "Abgelaufen", "Abgelehnt" oder "Abgebrochen" aus dem System gelöscht werden.',
          label: 'Tage *',
          placeholder: 'Tage'
        },
        validityPeriod: {
          heading: 'Ablauffrist',
          subHeading: 'Frist, in der eine offene Anfrage gültig ist. Innerhalb dieser Frist kann sich der Kunde entscheiden, den Vertrag auf Basis der versendeten VZF anzunehmen.',
          label: 'Tage *',
          placeholder: 'Tage'
        },
        retentionPeriod: {
          heading: 'Vorhaltefrist',
          subHeading: 'Frist, nach welcher Anfragen im Status "Angenommen" aus dem System gelöscht werden.',
          label: 'Tage *',
          placeholder: 'Tage'
        }
      },
      buttons: {
        submit: 'Fristen speichern'
      }
    },
    mailTemplates: {
      heading: 'E-Mail-Vorlagen',
      heading_help: {
        default: 'Hier können Sie E-Mail-Vorlagen für den Versand Ihrer Anfragen erstellen und verwalten. \nKlicken Sie auf "Neue Vorlage", um ein neues E-Mail-Template zu erstellen. \nGeben Sie einen (möglichst eindeutigen) Namen für die Vorlage an, um diese bei der Anfragen-Erstellung besser zuordnen zu können. <p> Über den Button "Vorschau versenden" können Sie sich eine Test-E-Mail an Ihre E-Mail-Adresse zuschicken lassen.<p/> <p> Bitte beachten Sie, dass das Löschen von Mail-Vorlagen dazu führt, dass die Vorlage nicht mehr verwendet werden kann. Dies kann Auswirkungen auf Ihre Third Party Integration haben. Das Löschen von Vorlagen wird daher nicht empfohlen.',
        clerk: 'Hier sehen Sie alle bereits erstellten E-Mail-Vorlagen, die Sie für den Versand der Vorvertrags-Dokumente an Ihren Kunden verwenden können. <p> Über den Button "Vorschau versenden" können Sie sich eine Test-E-Mail an Ihre E-Mail-Adresse zuschicken lassen.<p/> <p>Bitte beachten Sie, dass Änderungen nur von Nutzern mit den Rollen "Administrator" oder "Manager" vorgenommen werden können. Falls Sie Änderungen vornehmen oder neue Vorlagen erstellen möchten, wenden Sie sich bitte an Ihren zuständigen Administrator.</p>'
      },
      table: {
        headers: {
          name: 'Vorlagen',
          createdOn: 'Erstellt',
          updatedOn: 'Aktualisiert',
          uuid: 'UUID'
        },
        dialogs: {
          preview: {
            title: 'Vorschau für "{template}" versenden',
            subTitle: 'Geben Sie eine E-Mail-Adresse ein, an die eine Vorschau dieser Vorlage versendet werden soll.',
            fields: {
              email: {
                label: 'E-Mail *',
                placeholder: 'E-Mail'
              }
            },
            buttons: {
              cancel: 'Abbrechen',
              confirm: 'Vorschau senden'
            }
          },
          delete: {
            title: 'Vorlage "{template}" löschen?',
            buttons: {
              cancel: 'Abbrechen',
              confirm: 'Löschen'
            }
          }
        },
        actions: {
          preview: 'Vorschau versenden',
          edit: 'E-Mail-Vorlage bearbeiten',
          delete: 'E-Mail-Vorlage löschen'
        }
      },
      sidebar: {
        fields: {
          name: {
            label: 'Name *',
            placeholder: 'Name'
          },
          subject: {
            label: 'Betreff *',
            placeholder: 'Betreff'
          },
          htmlText: {
            label: 'HTML-E-Mail-Vorlage *',
            placeholder: 'HTML-E-Mail-Vorlage'
          },
          plainText: {
            label: 'Klartext-E-Mail-Vorlage *',
            placeholder: 'Klartext-E-Mail-Vorlage'
          }
        },
        buttons: {
          create: 'Vorlage erstellen',
          update: 'Vorlage speichern'
        },
        texts: {
          templates: 'Erstellen Sie E-Mail-Vorlagen bitte sowohl im HTML- als auch im Klartext-Format, um einen Mailversand an alle Mail-Clients zu gewährleisten.'
        }
      },
      buttons: {
        create: 'Neue Vorlage'
      }
    },
    success: 'Erfolgreich gespeichert',
    error: 'Das Speichern ist fehlgeschlagen',
    dialogs: {
      leaveRouteConfirmation: {
        title: 'Seite verlassen?',
        text: 'Es gibt ungespeicherte Änderungen auf dieser Seite. Wenn Sie die Seite verlassen, werden diese verworfen.',
        buttons: {
          cancel: 'Bearbeiten',
          confirm: 'Verwerfen'
        }
      }
    }
  }
}

export default settings
