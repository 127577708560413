import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'requests' }
  },
  {
    path: '/requests',
    name: 'requests',
    component: () => import('../views/Requests')
  },
  {
    path: '/requests/create',
    name: 'createRequest',
    component: () => import('../views/requests/Create')
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import('../views/Documents')
  },
  {
    path: '/document-folders',
    name: 'documentFolders',
    component: () => import('../views/DocumentFolders')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/Settings')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/Users'),
    meta: {
      roles: ['admin']
    }
  },
  {
    path: '/templates',
    name: 'templates',
    component: () => import('../views/Templates')
  },
  {
    path: '/templates/create',
    name: 'createTemplate',
    component: () => import('../views/CreateTemplate'),
    meta: {
      roles: ['admin', 'manager']
    }
  },
  {
    path: '/templates/duplicate/:templateUuid',
    name: 'duplicateTemplate',
    component: () => import('../views/CreateTemplate'),
    props: true,
    meta: {
      roles: ['admin', 'manager']
    }
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/Help')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
