import colors from 'vuetify/lib/util/colors'
import { de } from 'date-fns/locale'

export default {
  version: '',
  baseUrl: 'http://localhost:9090',
  i18n: {
    locale: 'de',
    fallbackLocale: '%i18n.locale%',
    dateTimeLocale: 'de-DE',
    dateFnsLocale: de,
    defaultLocalDateStringOptions: { year: 'numeric', month: '2-digit', day: '2-digit' },
    defaultLocalDateTimeStringOptions: { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' },
    timezone: 'Europe/Berlin'
  },
  requests: {
    states: {
      colors: {
        accepted: colors.green.base,
        canceled: colors.blueGrey.base,
        declined: colors.red.base,
        expired: colors.orange.base,
        open: colors.blue.base
      }
    }
  },
  tests: {
    // regex for RFC 5322 compliant email validation
    // eslint-disable-next-line no-control-regex
    emailRegEx: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  },
  api: {
    base: 'http://localhost:8000',
    endpoints: {
      eeccRequests: {
        base: '%api.base%/eecc_requests',
        list: '%api.endpoints.eeccRequests.base%/',
        search: '%api.endpoints.eeccRequests.base%/search',
        create: '%api.endpoints.eeccRequests.base%/',
        get: '%api.endpoints.eeccRequests.base%/{uuid}',
        accept: '%api.endpoints.eeccRequests.base%/{uuid}/accept',
        cancel: '%api.endpoints.eeccRequests.base%/{uuid}/cancel',
        decline: '%api.endpoints.eeccRequests.base%/{uuid}/decline',
        resend: '%api.endpoints.eeccRequests.base%/{uuid}/resend',
        stats: '%api.endpoints.eeccRequests.base%/stats'
      },
      mailTemplates: {
        base: '%api.base%/mail_templates',
        list: '%api.endpoints.mailTemplates.base%/',
        create: '%api.endpoints.mailTemplates.base%/',
        get: '%api.endpoints.mailTemplates.base%/{uuid}',
        update: '%api.endpoints.mailTemplates.base%/{uuid}',
        delete: '%api.endpoints.mailTemplates.base%/{uuid}',
        preview: '%api.endpoints.mailTemplates.base%/{uuid}/preview'
      },
      files: {
        create: '%api.base%/files/',
        get: '%api.base%/files/{uuid}'
      },
      system: {
        ping: '%api.base%/system/ping'
      },
      settings: {
        get: '%api.base%/settings/',
        update: '%api.base%/settings/'
      },
      documentFolders: {
        base: '%api.base%/document_folders',
        list: '%api.endpoints.documentFolders.base%/',
        create: '%api.endpoints.documentFolders.base%/',
        get: '%api.endpoints.documentFolders.base%/{uuid}',
        update: '%api.endpoints.documentFolders.base%/{uuid}',
        delete: '%api.endpoints.documentFolders.base%/{uuid}',
        memberships: {
          list: '%api.endpoints.documentFolders.base%/{uuid}/',
          create: '%api.endpoints.documentFolders.base%/{uuid}/',
          get: '%api.endpoints.documentFolders.base%/{uuid}/{membershipUuid}',
          update: '%api.endpoints.documentFolders.base%/{uuid}/{membershipUuid}',
          delete: '%api.endpoints.documentFolders.base%/{uuid}/{membershipUuid}'
        }
      },
      documents: {
        base: '%api.base%/documents',
        list: '%api.endpoints.documents.base%/',
        create: '%api.endpoints.documents.base%/',
        get: '%api.endpoints.documents.base%/{uuid}',
        update: '%api.endpoints.documents.base%/{uuid}',
        delete: '%api.endpoints.documents.base%/{uuid}',
        versions: {
          list: '%api.endpoints.documents.base%/{uuid}/',
          create: '%api.endpoints.documents.base%/{uuid}/',
          get: '%api.endpoints.documents.base%/{uuid}/{versionUuid}',
          delete: '%api.endpoints.documents.base%/{uuid}/{versionUuid}'
        }
      },
      contractSummaries: {
        base: '%api.base%/contract_summaries',
        list: '%api.endpoints.contractSummaries.base%/',
        create: '%api.endpoints.contractSummaries.base%/',
        read: '%api.endpoints.contractSummaries.base%/{templateUuid}',
        delete: '%api.endpoints.contractSummaries.base%/{templateUuid}',
        preview: '%api.endpoints.contractSummaries.base%/preview',
        defaultValues: '%api.endpoints.contractSummaries.base%/default'
      },
      markdownPages: {
        base: '%api.base%/markdown_pages',
        read: '%api.endpoints.markdownPages.base%/{markdownPageUuid}',
        update: '%api.endpoints.markdownPages.base%/{markdownPageUuid}'
      }
    }
  },
  keycloak: {
    realm: 'bdms',
    clientId: 'frontend',
    token: {
      minValidity: 60,
      refreshInterval: 20
    },
    roles: {
      admin: 'RoleAdministrator',
      manager: 'RoleManager',
      clerk: 'RoleClerk'
    },
    api: {
      base: 'http://localhost:8080',
      auth: '%keycloak.api.base%/auth',
      realm: '%keycloak.api.auth%/admin/realms/%keycloak.realm%',
      groups: '%keycloak.api.realm%/groups',
      users: {
        list: '%keycloak.api.realm%/users',
        create: '%keycloak.api.realm%/users',
        update: '%keycloak.api.realm%/users/{userId}',
        delete: '%keycloak.api.realm%/users/{userId}',
        sendMail: '%keycloak.api.realm%/users/{userId}/execute-actions-email',
        groups: '%keycloak.api.realm%/users/{userId}/groups',
        addToGroup: '%keycloak.api.realm%/users/{userId}/groups/{groupId}',
        removeFromGroup: '%keycloak.api.realm%/users/{userId}/groups/{groupId}'
      }
    }
  }
}
