import { createClient } from './httpClient'
import config from '@/config/config'
import { normalizeRequest, normalizeResponse } from '@/helpers/api.helper'

const endpoints = () => config.get('api.endpoints.eeccRequests')

const httpClient = createClient()

httpClient.interceptors.request.use(normalizeRequest)
httpClient.interceptors.response.use(normalizeResponse)

export const createRequest = (
  contractSummaryTemplate,
  customer,
  documentFolderUuid,
  expiryDate,
  predecessorEeccRequestUuid,
  surrogatCustomerUid,
  mailTemplateUuid,
  comment
) => {
  const data = { contractSummaryTemplate, customer, documentFolderUuid, surrogatCustomerUid }

  if (expiryDate) {
    data.expiryDate = expiryDate
  }

  if (mailTemplateUuid) {
    data.mailTemplateUuid = mailTemplateUuid
  }

  if (predecessorEeccRequestUuid) {
    data.predecessorEeccRequestUuid = predecessorEeccRequestUuid
  }

  if (comment) {
    data.comment = comment
  }

  return httpClient.post(endpoints().get('create'), data)
}

export const getRequest = (uuid) => httpClient.get(endpoints().get('get', { uuid }))

export const updateRequest = (uuid, { email, surrogatCustomerUid }) => httpClient.put(endpoints().get('get', { uuid }), { email, surrogatCustomerUid })

export const getRequests = (limit, offset) => httpClient.get(endpoints().get('list'), { params: { limit, skip: offset } })

export const searchRequests = (limit, offset, searchTerm) => httpClient.get(endpoints().get('search'), {
  params: { limit, skip: offset, query_term: searchTerm }
})

export const acceptRequest = (uuid) => httpClient.post(endpoints().get('accept', { uuid }))

export const cancelRequest = (uuid) => httpClient.post(endpoints().get('cancel', { uuid }))

export const declineRequest = (uuid) => httpClient.post(endpoints().get('decline', { uuid }))

export const resendRequest = (uuid) => httpClient.post(endpoints().get('resend', { uuid }))
