var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"color":"primary","dark":""}},[(_vm.logo)?[_c('router-link',{attrs:{"custom":"","to":{ name: 'home' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"d-flex align-center",attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"mr-2 my-2",attrs:{"alt":"Logo","height":"48","width":"auto","src":_vm.logo}})])]}}],null,false,311053445)}),_c('v-divider',{staticClass:"mx-4 hidden-md-and-down",attrs:{"vertical":""}})]:_vm._e(),_vm._l((_vm.navComputed),function(ref,i){
var name = ref.name;
return _c('v-btn',{key:i,staticClass:"hidden-md-and-down mr-1",attrs:{"to":{ name: name },"text":"","small":""}},[_vm._v(" "+_vm._s(_vm.getPageTitle(name))+" ")])}),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":"","min-width":200},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-md-and-down",attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.mdiAccount))])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'help' }}},[_vm._v(" "+_vm._s(_vm.helpText)+" ")]),_c('v-list-item',{attrs:{"href":_vm.getAccountPageUrl()}},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('v-list-item',{on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.logoutText)+" ")])],1)],1),_c('v-app-bar-nav-icon',{staticClass:"hidden-lg-and-up",on:{"click":_vm.openDrawer}})],2),_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":"","right":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.navComputed),function(ref,i){
var name = ref.name;
return _c('v-list-item',{key:i,attrs:{"to":{ name: name }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.getPageTitle(name)))])],1)],1)}),1)],1),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{attrs:{"to":{ name: 'help' }},on:{"click":_vm.closeDrawer}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.helpText))])],1)],1),_c('v-list-item',{attrs:{"href":_vm.getAccountPageUrl()},on:{"click":_vm.closeDrawer}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.name))])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.logoutText))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }